import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Card } from 'components/common/Card/Card';
import { Col, Divider, Row, Space, Typography, message, Drawer } from 'antd';
import { BrandSpan, FormWrapper } from '@app/components/layouts/ModalLayout/ModalLayout.styles';
import { Button } from '@app/components/common/buttons/Button/Button.styles';
import { BankFilled, CheckCircleFilled, CloseCircleOutlined, RightCircleFilled } from '@ant-design/icons';
import { FONT_FAMILY, FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import styled from 'styled-components';
import { useParams, useSearchParams } from 'react-router-dom';
import { ClaimModel } from '@app/domain/ClaimModel';
import { createClaimActivityPublic, createClaimPaymentPlanPublic, getClaimPublic } from '@app/api/claims.api';
import { format } from 'date-fns';
import { Loading } from '@app/components/common/Loading';
import { PaymentModel, EnumPaymentPaymentType, NEGATIVE_PAYMENT_TYPES } from '@app/domain/PaymentModel';
import { Collapse, Panel } from '@app/components/common/Collapse/Collapse';
import { DunningFeeFactor, getCreditorInterest, getDunningFee } from '@app/utils/claimUtils';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { RadioButton, RadioGroup } from '@app/components/common/Radio/Radio';
import { generatePaymentPage } from '@app/api/payments.api';
import { Helmet } from 'react-helmet-async';
import { Popconfirm } from '@app/components/common/Popconfirm/Popconfirm';
import { ReactComponent as GooglePayIcon } from '@app/assets/icons/googlepay.svg';
import { ReactComponent as ApplePayIcon } from '@app/assets/icons/applepay.svg';
import { ReactComponent as PayPalIcon } from '@app/assets/icons/paypal.svg';
import { ReactComponent as VisaMasterCardIcon } from '@app/assets/icons/visa_mastercard.svg';
import { LINKS } from '@app/constants/links';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { GERMAN_SPEAKING_COUNTRIES } from '@app/domain/AddressModel';
import { notificationController } from '@app/controllers/notificationController';
import { LanguagePicker } from '@app/components/header/components/settingsDropdown/settingsOverlay/LanguagePicker/LanguagePicker';

const PayPage: React.FC = () => {
  const { t } = useTranslation();

  const formRef = useRef<HTMLDivElement>(null);
  const theme = useAppSelector((state) => state.theme.theme);

  const [isPaymentProcessActive, setIsPaymentProcessActive] = useState(false);
  const [currentTransactionSecret, setCurrentTransactionSecret] = useState('');
  const [showPaymentPlan, setShowPaymentPlan] = useState(false);
  const [claimSolved, setClaimSolved] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);
  const [paymentPlanAgreement, setPaymentPlanAgreement] = useState(false);

  const [selectedPaymentFrequency, setSelectedPaymentFrequency] = useState(3);
  const [paymentPlanCreditorInterest, setPaymentPlanCreditorInterest] = useState(0);

  const { claimId, claimReferencePrefix, claimReference } = useParams();

  const [searchParams] = useSearchParams();
  const customAmount = searchParams.get('customAmount');

  const [claim, setClaim] = useState<ClaimModel>();
  const [isError, setIsError] = useState<boolean>(false);

  const numberFormat = Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: claim?.currency || 'EUR',
  });
  const numberFormatEUR = Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });

  const [toggleDrawer, setToggleDrawer] = useState<boolean>(false);

  const handleToggleDrawer = () => {
    setToggleDrawer(!toggleDrawer);
  };

  let availablePaymentPlanMonths = [2, 3, 4, 6, 9, 12];
  if (claim?.creditor && claim.creditor.maximumPaymentPlanMonths !== 0) {
    availablePaymentPlanMonths = availablePaymentPlanMonths.filter(function (x) {
      return x <= (claim?.creditor?.maximumPaymentPlanMonths || 12);
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (claimId && claimReference) {
          let response;
          if (claimReferencePrefix) {
            response = await getClaimPublic({ claimId, claimReference: claimReferencePrefix + '/' + claimReference });
          } else {
            response = await getClaimPublic({ claimId, claimReference });
          }
          if (response) {
            setClaim(response as ClaimModel);
            if (response && response.totalPending != null && response.totalPending <= 0) {
              setClaimSolved(true);
            }
          }
        }
      } catch (error) {
        setIsError(true);
        notificationController.error({ message: 'Error fetching claim details' });
      }
    };

    fetchData();
  }, [claimId, claimReferencePrefix, claimReference]);

  const payments: PaymentModel[] | undefined = claim?.payments;

  // latest costs
  const latestDunningCostExpense = payments?.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.DunningCostExpense,
  );
  const latestDunningCostFee = payments?.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.DunningCostFee,
  );
  const latestDefaultInterest = payments?.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.DefaultInterest,
  );
  const latestCreditorExtras = payments?.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.ExistingCreditorExtras,
  );
  const latestCreditorInterest = payments?.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.CreditorInterest,
  );
  const latestDebtCollectorTax = payments?.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.DebtCollectorTax,
  );

  const incomingPayments = payments?.filter(
    (payment) =>
      payment.paymentType &&
      NEGATIVE_PAYMENT_TYPES.includes(payment.paymentType as EnumPaymentPaymentType) &&
      payment.paymentType !== EnumPaymentPaymentType.WriteOffDiscount,
  );

  const writeOffs = payments?.filter((payment) => payment.paymentType === EnumPaymentPaymentType.WriteOffDiscount);
  const writeOffsTotal: number =
    writeOffs?.reduce(function (a, b) {
      return a + b.amount;
    }, 0) || 0;

  const existingPayments = payments?.filter(
    (payment) => payment.paymentType === EnumPaymentPaymentType.ExistingPayments,
  );
  const existingPaymentsTotal: number =
    existingPayments?.reduce(function (a, b) {
      return a + b.amount;
    }, 0) || 0;

  const paymentPlanFees: number = getDunningFee(
    (claim?.originalAmountDue || 0) - (claim?.totalPaid || 0),
    claim?.debtor?.address?.country && !GERMAN_SPEAKING_COUNTRIES.includes(claim.debtor.address.country)
      ? DunningFeeFactor.Court
      : DunningFeeFactor.Paymentplan,
  );

  useEffect(() => {
    setPaymentPlanCreditorInterest(
      getCreditorInterest(
        (claim?.originalAmountDue || 0) - (claim?.totalPaid || 0),
        claim?.dueDate || new Date(),
        claim?.interestRate || 0,
        ((claim?.totalPending || 0) - (latestCreditorInterest?.amount || 0) + paymentPlanFees) /
          selectedPaymentFrequency,
        'Monthly',
      ).value,
    );
  }, [claim, latestCreditorInterest, paymentPlanCreditorInterest, paymentPlanFees, selectedPaymentFrequency]);

  const handleClaimActivityButton = async (
    claim: ClaimModel,
    activityResponse: 'ReachedPromisedToPay' | 'ReachedPaymentPlanSetup',
  ) => {
    try {
      setClaimLoading(true);
      const resultActivity = await createClaimActivityPublic({
        claimId: claim.id,
        claimReference: claim.reference,
        activity: {
          activityType: 'Response',
          result: activityResponse,
          isPlanned: false,
        },
      });
      if (activityResponse === 'ReachedPaymentPlanSetup') {
        if (resultActivity?.id) {
          try {
            const resultPaymentPlan = await createClaimPaymentPlanPublic({
              claimId: claim.id,
              claimReference: claim.reference,
              paymentPlan: {
                numberOfPayments: selectedPaymentFrequency,
                frequency: 'Monthly',
              },
            });
            setClaimLoading(false);
            if (resultPaymentPlan?.id) {
              setClaimSolved(true);
              if (activityResponse === 'ReachedPaymentPlanSetup') {
                message.success(t('pay.successPaymentPlan'), 10000);
              } else {
                message.success(t('pay.success'), 10000);
              }
            } else {
              message.error(t('pay.errorPaymentPlan'), 10000);
            }
          } catch (e) {
            message.error(t('pay.errorPaymentPlan'), 10000);
          }
        }
      } else {
        message.success(t('pay.success'), 10000);
        setClaimSolved(true);
        setClaimLoading(false);
      }
    } catch (e) {
      setClaimLoading(false);
      message.error(
        <>
          {t('pay.loadingError')} <a href="mailto:dunning@collections.debtist.de">{t('pay.loadingErrorContact')}</a>
        </>,
      );
    }
  };

  const handlePaymentButton = async (paymentRef: string, paymentAmount: number, currency?: string) => {
    const DEFAULT_PAYMENT_CURRENCY: string = 'EUR';
    if (!currency) {
      currency = DEFAULT_PAYMENT_CURRENCY;
    }

    if (!paymentAmount) {
      return;
    }
    setIsPaymentProcessActive(true);
    try {
      const paymentPageResponse = await generatePaymentPage(paymentRef, currency, paymentAmount)?.then((data) => data);

      if (paymentPageResponse) {
        setCurrentTransactionSecret(paymentPageResponse);
      } else {
        setIsPaymentProcessActive(false);
      }

      setTimeout(() => {
        setIsPaymentProcessActive(false);
      }, 1000);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  const supportedCurrenciesForOnlinePayment = [
    'EUR',
    'CHF',
    'DKK',
    'GBP',
    'NOK',
    'PLN',
    'SEK',
    'USD',
    'JPY',
    'AED',
    'AUD',
    'SGD',
    'RON',
    'CAD',
    'MXN',
  ];
  const isOnlinePaymentAvailable = Boolean(
    claim &&
      claim.totalPending &&
      (!claim.currency || supportedCurrenciesForOnlinePayment.includes(claim.currency)) &&
      (claim.totalPending < 10000 || (claim.totalPending < 1000000 && claim.currency === 'JPY')) &&
      claim?.debtor?.address?.country, // online payment requires address data atm
  );

  const hasPaymentPlan = Boolean(claim && claim.paymentPlans?.length);

  // todo: allow payment plan rates to be paid online later?
  // const paymentAmount = selectedPaymentFrequency > 1 && claim?.payments ? claim?.payments[0]?.amount : 0;

  return (
    <>
      <PageTitle>{t('common.pay')}</PageTitle>
      <Helmet>
        <style>
          {`
          .novalnet-modal-icontent {
            min-height: 80%;
          }
          `}
        </style>
        {currentTransactionSecret !== '' && isPaymentProcessActive && (
          <>
            <script
              src={`https://paygate.novalnet.de/v2/checkout-1.0.0.js?t=${new Date().getTime()}`}
              id="novalnet-checkout-js"
              integrity="sha384-aDPsa/rYHZHxQVB8g9cvUke1bQgiQSaZM6VLi0L1dudq31rn3JG9T6R/0/OHIn9v"
              crossOrigin="anonymous"
            ></script>
            <script src={`https://cdn.novalnet.de/js/v2/NovalnetUtility.js`}></script>
            <script type="text/javascript">
              {`
            if (typeof Novalnet === 'undefined') {
              function checkVariableAvailability(variableName) {
                // Check if the variable is already available
                if (typeof window[variableName] !== 'undefined') {
                  Novalnet.setParam("nn_it", "overlay"); Novalnet.setParam("txn_secret", "${currentTransactionSecret}");
                  Novalnet.setParam("rftarget", "self");
                  Novalnet.render();
                  document.getElementById("novalnet-modal-div").addEventListener("click", () => {
                    Novalnet.Close();
                  });

                  clearInterval(intervalId);
                }
              }
              
              // Set an interval to check for the variable availability
              var intervalId = setInterval(() => {
                checkVariableAvailability('Novalnet');
              }, 100);
            }
            else {
              Novalnet.setParam("txn_secret", "${currentTransactionSecret}");
              Novalnet.render();
                document.getElementById("novalnet-modal-div").addEventListener("click", () => {
                Novalnet.Close();
              });
            }
        `}
            </script>
          </>
        )}
      </Helmet>
      {claim ? (
        <>
          <Row align={'middle'} justify={'space-between'}>
            <Space direction="vertical">
              <Row justify="center">
                {claim?.creditor?.imageUrl ? (
                  <img
                    src={claim?.creditor?.imageUrl}
                    alt={claim?.creditor?.businessName}
                    style={{
                      display: 'block',
                      marginBottom: 2,
                      maxWidth: 170,
                      border: '1px solid var(--secondary-color)',
                      borderRadius: 7,
                    }}
                  />
                ) : (
                  <Typography.Text style={{ fontSize: 16, fontWeight: 600 }}>
                    {claim?.creditor?.businessName || claim?.creditor?.contactName}
                  </Typography.Text>
                )}
              </Row>
              <Row justify="center">
                <BrandSpan
                  onClick={() => window.open('https://debtist.de', '_blank')}
                  style={{
                    cursor: 'pointer',
                    color: theme === 'dark' ? 'var(--text-nft-light-color)' : '',
                    paddingBottom: 3,
                  }}
                >
                  {t('common.poweredBy')} Debtist
                </BrandSpan>
              </Row>
            </Space>
            <LanguagePicker isExtended />
          </Row>

          <FormWrapper>
            <Row gutter={[20, 20]} justify={'center'}>
              <Col xs={24} md={24} xl={24}>
                <Card>
                  <Drawer
                    width={window.innerWidth > 900 ? 640 : window.innerWidth - 100}
                    placement="right"
                    closable={false}
                    onClose={handleToggleDrawer}
                    open={toggleDrawer}
                  >
                    <CloseCircleOutlined
                      onClick={handleToggleDrawer}
                      style={{
                        position: 'absolute',
                        right: 20,
                        top: 20,
                      }}
                    />
                    <Typography.Paragraph style={{ color: 'var(--text-nft-light-color)', marginTop: 40 }}>
                      {t('claim.total')}:{' '}
                      <Typography.Paragraph
                        style={{ color: 'var(--primary-color)', fontWeight: 'bold', fontFamily: FONT_FAMILY.code }}
                      >
                        {numberFormat.format(Math.max(claim?.totalPending || 0, 0))}
                      </Typography.Paragraph>
                    </Typography.Paragraph>
                    <Typography.Paragraph style={{ color: 'var(--text-nft-light-color)' }}>
                      {t('pay.paymentReference')}:
                      <br />
                      <Typography.Paragraph
                        copyable
                        style={{
                          color: 'var(--primary-color)',
                          fontWeight: 'bold',
                          fontFamily: FONT_FAMILY.code,
                        }}
                      >
                        {claim?.reference}
                      </Typography.Paragraph>
                    </Typography.Paragraph>
                    <Typography.Paragraph style={{ color: 'var(--text-nft-light-color)' }}>
                      {t('pay.iban')}:
                      <br />
                      <Typography.Paragraph
                        copyable
                        style={{
                          color: 'var(--primary-color)',
                          fontWeight: 'bold',
                          fontFamily: FONT_FAMILY.code,
                        }}
                      >
                        {claim.reference?.endsWith('CS')
                          ? 'DE43 5506 0417 0100 2411 21'
                          : claim.currency && claim.currency !== 'EUR'
                          ? 'DE88 1004 0000 0250 3142 01'
                          : 'DE88 1004 0000 0250 3142 01'}
                      </Typography.Paragraph>
                    </Typography.Paragraph>
                    {!claim.reference?.endsWith('CS') && (
                      <Typography.Paragraph style={{ color: 'var(--text-nft-light-color)' }}>
                        {t('pay.bic')}:
                        <br />
                        <Typography.Paragraph
                          copyable
                          style={{
                            color: 'var(--primary-color)',
                            fontWeight: 'bold',
                            fontFamily: FONT_FAMILY.code,
                          }}
                        >
                          {claim.currency && claim.currency !== 'EUR' ? 'COBADEFFXXX' : 'COBADEFFXXX'}
                        </Typography.Paragraph>
                      </Typography.Paragraph>
                    )}
                    <Typography.Paragraph style={{ color: 'var(--text-nft-light-color)' }}>
                      {t('pay.recipient')}:
                      <br />
                      <Typography.Paragraph
                        copyable
                        style={{
                          color: 'var(--primary-color)',
                          fontWeight: 'bold',
                          fontFamily: FONT_FAMILY.code,
                        }}
                      >
                        {claim.reference?.endsWith('CS') ? 'CS Collection Services' : 'Debtist'}
                      </Typography.Paragraph>
                    </Typography.Paragraph>
                    <Divider />
                    <Typography.Paragraph style={{ color: 'var(--text-nft-light-color)' }}>
                      {t('pay.due')}: <b>{t('pay.now')}</b>
                      <br />
                      {(!claim || !claim.paymentPlans || claim.paymentPlans.length === 0) &&
                        claim?.creditor?.maximumPaymentPlanMonths !== 1 && (
                          <SubtitleBalanceText>
                            {t('pay.alternative')}:{' '}
                            <a
                              onClick={() => {
                                if (!claimSolved && !hasPaymentPlan) {
                                  setShowPaymentPlan(true);
                                  setTimeout(() => {
                                    formRef?.current?.scrollIntoView({ behavior: 'smooth' });
                                  }, 100);
                                }
                              }}
                            >
                              {t('pay.splitPayment')}
                            </a>
                            .
                          </SubtitleBalanceText>
                        )}
                    </Typography.Paragraph>
                    <Divider />
                    {renderPaymentExplanation()}
                    <Divider />
                    <Popconfirm
                      placement="top"
                      title={t('pay.confirmPayment')}
                      okButtonProps={{ type: 'primary', block: true, style: { width: '48%' } }}
                      cancelButtonProps={{ block: true, style: { width: '48%' } }}
                      onConfirm={async () => handleClaimActivityButton(claim, 'ReachedPromisedToPay')}
                      okText={t('popconfirm.yes')}
                      cancelText={t('popconfirm.no')}
                    >
                      <Button
                        icon={<CheckCircleFilled style={{ width: 20 }} />}
                        type="default"
                        block
                        loading={claimLoading}
                        disabled={claimSolved}
                      >
                        {t('common.markAsPaid')}
                      </Button>
                    </Popconfirm>
                  </Drawer>
                  <Typography.Paragraph color={'var(--text-nft-light-color)'}>
                    {t('pay.greeting')} <b>{claim?.debtor?.businessName || claim?.debtor?.contactName}</b>,
                  </Typography.Paragraph>
                  <Typography.Paragraph color={'var(--text-nft-light-color)'}>
                    {t('pay.intro1')} <b>{claim?.creditor?.businessName || claim?.creditor?.contactName}</b>
                    {t('pay.intro2')} <i>{isOnlinePaymentAvailable ? t('pay.payOnline') : t('common.payNow')}</i>.
                  </Typography.Paragraph>
                  <Typography.Paragraph color={'var(--text-nft-light-color)'}>{t('pay.intro3')}</Typography.Paragraph>
                  {claim?.invoiceCreatedAt && (
                    <Typography.Paragraph color={'var(--text-nft-light-color)'}>
                      {t('pay.invoiceDate')}: {format(new Date(claim?.invoiceCreatedAt), 'dd.MM.yyyy')}.<br />
                      {t('pay.claimTitle')}: {claim?.title}.<br />
                      {t('claim.total')}:{' '}
                      <b>
                        {numberFormat.format(
                          Math.max((customAmount && Number(customAmount)) || claim?.totalPending || 0, 0),
                        )}
                      </b>
                      .
                    </Typography.Paragraph>
                  )}
                  <Typography.Link onClick={handleToggleDrawer}>{t('pay.paymentSummary')}</Typography.Link>
                  <>
                    {claim?.creditor?.maximumPaymentPlanMonths !== 1 && (
                      <>
                        <Row justify="center" style={{ paddingTop: 30 }}>
                          <Button
                            block
                            type={hasPaymentPlan ? 'default' : 'primary'}
                            style={{ maxWidth: 400, display: 'block' }}
                            disabled={hasPaymentPlan || claimSolved}
                            onClick={() => {
                              setShowPaymentPlan(true);
                              setTimeout(() => {
                                formRef?.current?.scrollIntoView({ behavior: 'smooth' });
                              }, 100);
                            }}
                          >
                            {hasPaymentPlan ? t('common.paymentPlanActive') : t('common.createPaymentPlan')}
                          </Button>
                        </Row>
                        <SpaceRow justify="center">
                          <SubtitleBalanceText>{t('common.or')}</SubtitleBalanceText>
                        </SpaceRow>
                      </>
                    )}
                    {isOnlinePaymentAvailable ? (
                      <>
                        <Row justify="center">
                          <Button
                            icon={<RightCircleFilled style={{ width: 20 }} />}
                            block
                            type={'ghost'}
                            style={{ maxWidth: 400 }}
                            disabled={isPaymentProcessActive}
                            onClick={() =>
                              handlePaymentButton(
                                claim?.reference,
                                (customAmount && Number(customAmount)) || claim?.totalPending || 0,
                                claim?.currency,
                              )
                            }
                            loading={isPaymentProcessActive}
                          >
                            {t('pay.payOnline')}
                          </Button>
                          <Row
                            onClick={() =>
                              handlePaymentButton(
                                claim?.reference,
                                (customAmount && Number(customAmount)) || claim?.totalPending || 0,
                                claim?.currency,
                              )
                            }
                            justify="center"
                            style={{
                              marginTop: 10,
                              width: '100%',
                              alignItems: 'center',
                              justifyContent: 'center',
                              display: 'flex',
                              gap: 8,
                              cursor: 'pointer',
                            }}
                          >
                            <VisaMasterCardIcon
                              height={70}
                              width={120}
                              style={{
                                background: theme === 'dark' ? 'white' : '',
                                borderRadius: 3,
                                padding: theme === 'dark' ? 6 : 0,
                                height: 45,
                              }}
                            />
                            <PayPalIcon
                              height={65}
                              width={90}
                              style={{
                                background: theme === 'dark' ? 'white' : '',
                                borderRadius: 3,
                                height: 45,
                              }}
                            />
                            <ApplePayIcon
                              height={30}
                              width={70}
                              style={{
                                background: theme === 'dark' ? 'white' : '',
                                borderRadius: 3,
                                height: 45,
                                padding: theme === 'dark' ? 6 : 0,
                              }}
                            />
                            <GooglePayIcon
                              height={33}
                              width={90}
                              style={{
                                background: theme === 'dark' ? 'white' : '',
                                borderRadius: 3,
                                height: 45,
                                padding: 2,
                              }}
                            />
                          </Row>
                        </Row>
                        {isPaymentProcessActive && (
                          <Row justify="center">
                            <SubtitleBalanceText>{t('pay.loadingPayment')}</SubtitleBalanceText>
                          </Row>
                        )}
                      </>
                    ) : (
                      <Row justify="center">
                        <Button
                          icon={<RightCircleFilled style={{ width: 20 }} />}
                          type="default"
                          style={{ maxWidth: 400 }}
                          block
                          onClick={handleToggleDrawer}
                          loading={claimLoading}
                          disabled={claimSolved}
                        >
                          <span style={{ width: 'fit-content' }}>{t('common.payNow')}</span>
                        </Button>
                      </Row>
                    )}
                  </>
                </Card>
              </Col>
              <Col xs={12}>
                <Row justify={'start'} style={{ color: 'var(--text-nft-light-color)' }}>
                  <Typography.Text style={{ color: 'var(--text-nft-light-color)', fontSize: 14 }}>
                    {'Debtist GmbH'},<br />
                    {t('common.legalSubtitle')}
                  </Typography.Text>
                </Row>
              </Col>
              <Col xs={12}>
                <Row justify={'end'}>
                  <a target="_blank" rel="noreferrer" href={LINKS.privacy}>
                    {t('signup.privacyOPolicy')}
                  </a>
                </Row>
              </Col>

              {showPaymentPlan && (
                <Col ref={formRef} xs={24} md={24} xl={24}>
                  <Divider style={{ marginTop: 25 }}>
                    <Typography.Title level={5} style={{ color: 'var(--text-nft-light-color)' }}>
                      {t('common.paymentPlan')}
                    </Typography.Title>
                  </Divider>
                  <Card autoHeight={false}>
                    <Row>
                      <Col xs={24} md={14} xl={14}>
                        <Row>
                          <Typography.Paragraph style={{ color: 'var(--text-nft-light-color)' }}>
                            {t('pay.paymentPlanDurationDescription')}:
                          </Typography.Paragraph>
                        </Row>
                        <Row>
                          <RadioGroup
                            value={selectedPaymentFrequency}
                            onChange={(e) => setSelectedPaymentFrequency(e.target.value)}
                            size="small"
                          >
                            {availablePaymentPlanMonths.map((month) => (
                              <RadioButton key={month} value={month} style={{ paddingLeft: 20, paddingRight: 20 }}>
                                {month}
                              </RadioButton>
                            ))}
                          </RadioGroup>
                        </Row>
                        <Row style={{ marginBottom: 6, marginTop: 12 }}>
                          <Checkbox
                            checked={paymentPlanAgreement}
                            onChange={(e) => setPaymentPlanAgreement(e.target.checked)}
                          >
                            <SubtitleBalanceText style={{ display: 'block', maxWidth: 350 }}>
                              {t('pay.paymentPlanAgree')}
                            </SubtitleBalanceText>
                          </Checkbox>
                        </Row>
                        <Row style={{ marginBottom: 6, marginTop: 12 }}>
                          <Button
                            icon={<BankFilled style={{ maxWidth: 30 }} />}
                            loading={claimLoading}
                            disabled={claimSolved || !paymentPlanAgreement}
                            type="primary"
                            onClick={async () => handleClaimActivityButton(claim, 'ReachedPaymentPlanSetup')}
                            style={{ width: '100%', maxWidth: 350 }}
                          >
                            {t('common.submitPaymentPlan')}
                          </Button>
                        </Row>
                        <Row style={{ marginBottom: 6, marginTop: 12 }}>
                          <SubtitleBalanceText
                            style={{
                              maxWidth: 350,
                            }}
                          >
                            {t('pay.paymentPlanSuffix')}
                          </SubtitleBalanceText>
                        </Row>
                      </Col>
                      <Col xs={24} md={10} xl={10}>
                        <Row>
                          <Typography.Paragraph style={{ color: 'var(--text-nft-light-color)' }}>
                            {t('pay.currentRate')}:
                          </Typography.Paragraph>
                        </Row>
                        <Row>
                          <Typography.Title level={2}>
                            {numberFormat.format(
                              ((claim?.totalPending || 0) -
                                (latestCreditorInterest?.amount || 0) +
                                paymentPlanFees +
                                paymentPlanCreditorInterest) /
                                selectedPaymentFrequency,
                            )}{' '}
                            / {t('common.month')}
                          </Typography.Title>
                          <SubtitleBalanceText>
                            {latestDebtCollectorTax && (
                              <>
                                {t('pay.excludingTaxes')}
                                <br />
                                <br />
                              </>
                            )}
                            {t('pay.paymentPlanNote')}
                            <br />
                            {t('pay.paymentPlanWarning')}
                            <br />
                            <br />
                            {t('pay.paymentPlanExpenses')}: {numberFormatEUR.format(paymentPlanFees)}
                            {latestDebtCollectorTax && ' ' + t('pay.excludingTaxes')}
                          </SubtitleBalanceText>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                  <br />
                </Col>
              )}
            </Row>
          </FormWrapper>
        </>
      ) : isError || !claimId || !claimReference ? (
        <>
          <SubtitleBalanceText style={{ marginTop: 12 }}>
            {t('pay.loadingError')} <a href="mailto:dunning@collections.debtist.de">{t('pay.loadingErrorContact')}</a>
          </SubtitleBalanceText>
        </>
      ) : (
        <>
          <Loading />
          <SubtitleBalanceText style={{ marginTop: 12 }}>{t('pay.loading')}...</SubtitleBalanceText>
        </>
      )}
    </>
  );

  function renderPaymentExplanation() {
    return (
      <CollapseWrapper ghost>
        <Panel header={t('pay.explainCosts')} key="1">
          <Typography.Text style={{ fontSize: 14 }}>
            {t('claim.originalAmountDue')},
            <br />
            {claim?.title}: <b>{numberFormat.format(claim?.originalAmountDue || 0)}</b>
          </Typography.Text>
          {existingPaymentsTotal !== 0 && (
            <div>
              <Typography.Text style={{ fontSize: 14 }}>
                {t('claim.payment.ExistingPayments')}: {numberFormat.format(existingPaymentsTotal)}{' '}
              </Typography.Text>
            </div>
          )}
          {latestCreditorExtras && (
            <div>
              <Typography.Text style={{ fontSize: 14 }}>
                {t('claim.payment.ExistingCreditorExtras')}: +{numberFormatEUR.format(latestCreditorExtras.amount)}{' '}
              </Typography.Text>
              <SubtitleBalanceText>
                {latestCreditorExtras.paymentDate && format(new Date(latestCreditorExtras.paymentDate), 'dd.MM.yyyy')}
              </SubtitleBalanceText>
            </div>
          )}
          {latestDunningCostExpense && (
            <div>
              <Typography.Text style={{ fontSize: 14 }}>
                {t('claim.payment.DunningCostExpense')}: +{numberFormatEUR.format(latestDunningCostExpense.amount)}{' '}
              </Typography.Text>
              <SubtitleBalanceText>
                {latestDunningCostExpense.paymentDate &&
                  format(new Date(latestDunningCostExpense.paymentDate), 'dd.MM.yyyy')}
              </SubtitleBalanceText>
            </div>
          )}
          {latestDunningCostFee && (
            <div>
              <Typography.Text style={{ fontSize: 14 }}>
                {t('claim.payment.DunningCostFee')}: +{numberFormatEUR.format(latestDunningCostFee.amount)}{' '}
              </Typography.Text>
              <SubtitleBalanceText>
                {latestDunningCostFee.paymentDate && format(new Date(latestDunningCostFee.paymentDate), 'dd.MM.yyyy')}
              </SubtitleBalanceText>
            </div>
          )}
          {latestDefaultInterest && (
            <div>
              <Typography.Text style={{ fontSize: 14 }}>
                {t('claim.payment.DefaultInterest')}: +{numberFormatEUR.format(latestDefaultInterest.amount)}{' '}
              </Typography.Text>
              <SubtitleBalanceText>
                {latestDefaultInterest.paymentDate && format(new Date(latestDefaultInterest.paymentDate), 'dd.MM.yyyy')}
              </SubtitleBalanceText>
            </div>
          )}
          {latestCreditorInterest && (
            <div>
              <Typography.Text style={{ fontSize: 14 }}>
                {t('claim.payment.CreditorInterest')}: +{numberFormatEUR.format(latestCreditorInterest.amount)}{' '}
              </Typography.Text>
              <SubtitleBalanceText>
                {latestCreditorInterest.paymentDate &&
                  format(new Date(latestCreditorInterest.paymentDate), 'dd.MM.yyyy')}
              </SubtitleBalanceText>
            </div>
          )}
          {latestDebtCollectorTax && (
            <div>
              <Typography.Text style={{ fontSize: 14 }}>
                {t('claim.payment.DebtCollectorTax')}: +{numberFormatEUR.format(latestDebtCollectorTax.amount)}{' '}
              </Typography.Text>
              <SubtitleBalanceText>
                {latestDebtCollectorTax.paymentDate &&
                  format(new Date(latestDebtCollectorTax.paymentDate), 'dd.MM.yyyy')}
              </SubtitleBalanceText>
            </div>
          )}
          {incomingPayments &&
            Array.isArray(incomingPayments) &&
            incomingPayments.length > 0 &&
            incomingPayments
              ?.sort((a, b) => {
                if (a.paymentDate === null || b.paymentDate === null) {
                  return 0;
                }
                return new Date(a.paymentDate) < new Date(b.paymentDate) ? 1 : -1;
              })
              .map((payment) => (
                <div key={payment.id}>
                  <Typography.Text style={{ fontSize: 14 }}>
                    {t('claim.incomingPayment')}: {numberFormatEUR.format(payment.amount)}{' '}
                  </Typography.Text>
                  <SubtitleBalanceText>
                    {payment.paymentDate && format(new Date(payment.paymentDate), 'dd.MM.yyyy')}
                  </SubtitleBalanceText>
                </div>
              ))}
          {writeOffsTotal !== 0 && (
            <Typography.Text style={{ fontSize: 14 }}>
              {t('claim.writeOffs')}: {numberFormat.format(writeOffsTotal)}
            </Typography.Text>
          )}
        </Panel>
      </CollapseWrapper>
    );
  }
};

export const SubtitleBalanceText = styled(Typography.Text)`
  font-family: ${FONT_FAMILY.secondary};

  font-size: ${FONT_SIZE.xs};

  font-weight: ${FONT_WEIGHT.regular};

  color: var(--text-nft-light-color);
`;

export const SpaceRow = styled(Row)`
  margin: 1rem 0;
`;

export const CollapseWrapper = styled(Collapse)`
  width: auto;
  margin-bottom: 10px;
`;

export default PayPage;
