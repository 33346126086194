import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { RadioButton, RadioGroup } from '@app/components/common/Radio/Radio';
import { Option } from '@app/components/common/selects/Select/Select';
import * as S from '../StepForm.styles';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { CountriesSpace } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/CountriesItem/CountriesItem.styles';
import { Col, Row } from 'antd';

interface Field {
  name?: string;
  value: string;
}

interface Step2Props {
  formValues: Field[];
}

const PHONE_PREFIXES = new Map<string, string>([
  ['41', 'CH'], // Switzerland
  ['43', 'AT'], // Austria
  ['49', 'DE'], // Germany
  ['30', 'GR'], // Greece
  ['31', 'NL'], // Netherlands
  ['32', 'BE'], // Belgium
  ['33', 'FR'], // France
  ['34', 'ES'], // Spain
  ['36', 'HU'], // Hungary
  ['39', 'IT'], // Italy
  ['40', 'RO'], // Romania
  ['44', 'GB'], // UK
  ['45', 'DK'], // Denmark
  ['46', 'SE'], // Sweden
  ['47', 'NO'], // Norway
  ['48', 'PL'], // Poland
  ['351', 'PT'], // Portugal
  ['352', 'LU'], // Luxembourg
  ['353', 'IE'], // Ireland
  ['354', 'IS'], // Iceland
  ['355', 'AL'], // Albania
  ['356', 'MT'], // Malta
  ['357', 'CY'], // Cyprus
  ['358', 'FI'], // Finland
  ['359', 'BG'], // Bulgaria
  ['1', 'US'], // USA/Canada
  ['7', 'RU'], // Russia/Kazakhstan
  ['20', 'EG'], // Egypt
  ['27', 'ZA'], // South Africa
  ['55', 'BR'], // Brazil
  ['61', 'AU'], // Australia
  ['81', 'JP'], // Japan
  ['82', 'KR'], // South Korea
  ['86', 'CN'], // China
  ['91', 'IN'], // India
  ['90', 'TR'], // Turkey
  ['52', 'MX'], // Mexico
  ['972', 'IL'], // Israel
  ['216', 'TN'], // Tunisia
  ['213', 'DZ'], // Algeria
  ['212', 'MA'], // Morocco
  ['880', 'BD'], // Bangladesh
  ['375', 'BY'], // Belarus
  ['968', 'OM'], // Oman
  ['886', 'TW'], // Taiwan
  ['381', 'RS'], // Serbia
  ['234', 'NG'], // Nigeria
  ['256', 'UG'], // Uganda
  ['966', 'SA'], // Saudi Arabia
  ['852', 'HK'], // Hong Kong
  ['65', 'SG'], // Singapore
  ['56', 'CL'], // Chile
  ['971', 'AE'], // United Arab Emirates
  ['63', 'PH'], // Philippines
  ['60', 'MY'], // Malaysia
  ['84', 'VN'], // Vietnam
  ['66', 'TH'], // Thailand
  ['92', 'PK'], // Pakistan
  ['263', 'ZW'], // Zimbabwe
  ['254', 'KE'], // Kenya
  ['54', 'AR'], // Argentina
  ['51', 'PE'], // Peru
  ['57', 'CO'], // Colombia
  ['373', 'MD'], // Moldova
]);

export const countryOptions = [
  { code: 'DEU', short: 'DE' },
  { code: 'AUT', short: 'AT' },
  { code: 'CHE', short: 'CH' },
  { code: 'ITA', short: 'IT' },
  { code: 'NLD', short: 'NL' },
  { code: 'NZL', short: 'NZ' },
  { code: 'EST', short: 'EE' },
  { code: 'POL', short: 'PL' },
  { code: 'FRA', short: 'FR' },
  { code: 'ESP', short: 'ES' },
  { code: 'CHN', short: 'CN' },
  { code: 'BEL', short: 'BE' },
  { code: 'GBR', short: 'GB' },
  { code: 'LUX', short: 'LU' },
  { code: 'IRL', short: 'IE' },
  { code: 'TUR', short: 'TR' },
  { code: 'IND', short: 'IN' },
  { code: 'MEX', short: 'MX' },
  { code: 'ISR', short: 'IL' },
  { code: 'ISL', short: 'IS' },
  { code: 'BRA', short: 'BR' },
  { code: 'GRC', short: 'GR' },
  { code: 'TUN', short: 'TN' },
  { code: 'USA', short: 'US' },
  { code: 'DZA', short: 'DZ' },
  { code: 'PRT', short: 'PT' },
  { code: 'MAR', short: 'MA' },
  { code: 'BGR', short: 'BG' },
  { code: 'BGD', short: 'BD' },
  { code: 'BLR', short: 'BY' },
  { code: 'OMN', short: 'OM' },
  { code: 'TWN', short: 'TW' },
  { code: 'DNK', short: 'DK' },
  { code: 'DOM', short: 'DO' },
  { code: 'CAN', short: 'CA' },
  { code: 'AUS', short: 'AU' },
  { code: 'ZAF', short: 'ZA' },
  { code: 'SRB', short: 'RS' },
  { code: 'ROU', short: 'RO' },
  { code: 'NGA', short: 'NG' },
  { code: 'EGY', short: 'EG' },
  { code: 'UGA', short: 'UG' },
  { code: 'SAU', short: 'SA' },
  { code: 'HUN', short: 'HU' },
  { code: 'HKG', short: 'HK' },
  { code: 'CYM', short: 'KY' },
  { code: 'SGP', short: 'SG' },
  { code: 'CHL', short: 'CL' },
  { code: 'KOR', short: 'KR' },
  { code: 'RUS', short: 'RU' },
  { code: 'CZE', short: 'CZ' },
  { code: 'UKR', short: 'UA' },
  { code: 'LVA', short: 'LV' },
  { code: 'ARE', short: 'AE' },
  { code: 'SWE', short: 'SE' },
  { code: 'ALB', short: 'AL' },
  { code: 'BIH', short: 'BA' },
  { code: 'XKX', short: 'XK' },
  { code: 'HRV', short: 'HR' },
  { code: 'MNE', short: 'ME' },
  { code: 'MKD', short: 'MK' },
  { code: 'SVN', short: 'SI' },
  { code: 'SVK', short: 'SK' },
  { code: 'IDN', short: 'ID' },
  { code: 'NOR', short: 'NO' },
  { code: 'FIN', short: 'FI' },
  { code: 'MLT', short: 'MT' },
  { code: 'PHL', short: 'PH' },
  { code: 'MYS', short: 'MY' },
  { code: 'VNM', short: 'VN' },
  { code: 'KWT', short: 'KW' },
  { code: 'HND', short: 'HN' },
  { code: 'LTU', short: 'LT' },
  { code: 'THA', short: 'TH' },
  { code: 'PAK', short: 'PK' },
  { code: 'ZWE', short: 'ZW' },
  { code: 'KEN', short: 'KE' },
  { code: 'ARG', short: 'AR' },
  { code: 'PER', short: 'PE' },
  { code: 'COL', short: 'CO' },
  { code: 'GHA', short: 'GH' },
  { code: 'CYP', short: 'CY' },
  { code: 'QAT', short: 'QA' },
  { code: 'JPN', short: 'JP' },
  { code: 'JOR', short: 'JO' },
  { code: 'SEN', short: 'SN' },
  { code: 'LBN', short: 'LB' },
  { code: 'IRQ', short: 'IQ' },
  { code: 'SYR', short: 'SY' },
  { code: 'AFG', short: 'AF' },
  { code: 'YEM', short: 'YE' },
  { code: 'IRN', short: 'IR' },
  { code: 'ARM', short: 'AM' },
  { code: 'AZE', short: 'AZ' },
  { code: 'GEO', short: 'GE' },
  { code: 'KGZ', short: 'KG' },
  { code: 'KAZ', short: 'KZ' },
  { code: 'TKM', short: 'TM' },
  { code: 'UZB', short: 'UZ' },
  { code: 'TJK', short: 'TJ' },
  { code: 'ERI', short: 'ER' },
  { code: 'RUA', short: 'RW' },
  { code: 'BEN', short: 'BJ' },
  { code: 'CMR', short: 'CM' },
];

export const Step2: React.FC<Step2Props> = ({ formValues }) => {
  const { t } = useTranslation();

  const prefixSelector = (
    <BaseForm.Item name="phonePrefix" noStyle>
      <S.Select showSearch>
        {Array.from(PHONE_PREFIXES.entries()).map(([prefix, countryCode]) => (
          <Option key={prefix} value={prefix}>
            <ReactCountryFlag countryCode={countryCode} svg alt="country flag" />
            <span style={{ marginLeft: 5, fontSize: 12, marginRight: 10 }}>+{prefix}</span>
          </Option>
        ))}
      </S.Select>
    </BaseForm.Item>
  );

  return (
    <S.FormContent>
      <Row gutter={[30, 30]}>
        <Col xs={24}>
          <BaseForm.Item
            name="debtorBusinessType"
            label={t('forms.stepFormLabels.debtorBusinessType')}
            rules={[{ required: true, message: t('forms.stepFormLabels.debtorBusinessTypeError') }]}
          >
            <RadioGroup>
              <RadioButton value="Business">{t('businessType.business')}</RadioButton>
              <RadioButton value="Consumer">{t('businessType.consumer')}</RadioButton>
              <RadioButton value="Merchant">{t('businessType.merchant')}</RadioButton>
            </RadioGroup>
          </BaseForm.Item>
        </Col>
        <Col xs={24} sm={12} xl={12}>
          <BaseForm.Item
            name="debtorBusinessName"
            label={t('forms.stepFormLabels.debtorBusinessName')}
            rules={[
              {
                required:
                  formValues.find((item) => item.name == t('forms.stepFormLabels.debtorBusinessType'))?.value ===
                  'Business',
                message: t('forms.stepFormLabels.debtorBusinessNameError'),
              },
            ]}
          >
            <Input />
          </BaseForm.Item>
        </Col>
        <Col xs={24} md={12}></Col>
        <Col xs={24} md={24}>
          <BaseForm.Item
            name="debtorContactName"
            label={t('forms.stepFormLabels.debtorContactName')}
            rules={[
              {
                required:
                  formValues.find((item) => item.name == t('forms.stepFormLabels.debtorBusinessType'))?.value !==
                  'Business',
                message: t('forms.stepFormLabels.debtorContactNameError'),
              },
            ]}
            style={{ maxWidth: '50%', paddingRight: 15 }}
          >
            <Input />
          </BaseForm.Item>
        </Col>
        <Col xs={24} sm={12} xl={12}>
          <BaseForm.Item
            name="debtorEmail"
            label={t('forms.stepFormLabels.debtorEmail')}
            rules={[
              {
                type: 'email',
                message: t('common.notValidEmail'),
              },
            ]}
          >
            <Input />
          </BaseForm.Item>
        </Col>
        <Col xs={24} sm={12} xl={12}>
          <S.PhoneItem
            name="debtorPhone"
            label={t('forms.stepFormLabels.debtorPhone')}
            rules={[{ required: false, message: t('forms.stepFormLabels.debtorPhoneError') }]}
          >
            <Input addonBefore={prefixSelector} />
          </S.PhoneItem>
        </Col>
        <Col xs={24} sm={12} xl={12}>
          <BaseForm.Item
            name="debtorAddressLine1"
            label={`${t('forms.stepFormLabels.debtorAddressLine')} 1`}
            rules={[{ required: true, message: t('forms.stepFormLabels.debtorAddressError') }]}
          >
            <Input />
          </BaseForm.Item>
        </Col>
        <Col xs={24} sm={12} xl={12}>
          <BaseForm.Item
            name="debtorAddressLine2"
            label={`${t('forms.stepFormLabels.debtorAddressLine')} 2`}
            rules={[{ required: false, message: t('forms.stepFormLabels.debtorAddressError') }]}
          >
            <Input />
          </BaseForm.Item>
        </Col>
        <Col xs={24} sm={12} xl={12}>
          <BaseForm.Item
            name="debtorPostalcode"
            label={t('forms.stepFormLabels.debtorPostalcode')}
            rules={[{ required: true, message: t('forms.stepFormLabels.debtorPostalcodeError') }]}
          >
            <Input maxLength={10} />
          </BaseForm.Item>
        </Col>
        <Col xs={24} sm={12} xl={12}>
          <BaseForm.Item
            name="debtorTown"
            label={t('forms.stepFormLabels.debtorTown')}
            rules={[{ required: true, message: t('forms.stepFormLabels.debtorTownError') }]}
          >
            <Input />
          </BaseForm.Item>
        </Col>
        <Col xs={24} sm={12} xl={12}>
          <BaseForm.Item
            name="debtorCountry"
            label={t('forms.stepFormLabels.debtorCountry')}
            rules={[{ required: true, message: t('forms.stepFormLabels.countryError') }]}
          >
            <S.Select
              showSearch
              optionFilterProp="key"
              filterOption={(input, option) => option?.key?.toLowerCase().includes(input.toLowerCase())}
              defaultValue={'DEU'}
            >
              {countryOptions.map((country) => (
                <Option
                  key={t('forms.stepFormLabels.country.' + country.code.toLowerCase()).toString()}
                  value={country.code}
                >
                  <CountriesSpace align="center">
                    <ReactCountryFlag countryCode={country.short} svg alt="country flag" />
                    {t('forms.stepFormLabels.country.' + country.code.toLowerCase())}
                  </CountriesSpace>
                </Option>
              ))}
            </S.Select>
          </BaseForm.Item>
        </Col>
      </Row>
    </S.FormContent>
  );
};
