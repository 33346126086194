import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { ForgotPasswordForm } from '@app/components/auth/ForgotPasswordForm/ForgotPasswordForm';
import { Helmet } from 'react-helmet-async';
import { PageDescription } from '@app/components/common/PageDescription/PageDescription';

const ForgotPasswordPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <link rel="canonical" href={'/auth/forgot-password'} />
      </Helmet>
      <PageTitle>{t('common.forgotPasswordPage')}</PageTitle>
      <PageDescription>{t('common.forgotPasswordDescription')}</PageDescription>
      <ForgotPasswordForm />
    </>
  );
};

export default ForgotPasswordPage;
