import { DownOutlined, DownloadOutlined, UserOutlined } from '@ant-design/icons';
import { httpApi } from '@app/api/http.api';
import { GetPayoutsResponse, getPayouts } from '@app/api/payments.api';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { readToken } from '@app/services/localStorage.service';
import { sleep } from '@app/utils/utils';
import { Avatar, Col, Row, Space, Tooltip, notification } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { Payment } from 'api/paymentHistory.api';
import { Button } from 'components/common/buttons/Button/Button';
import { Dates } from 'constants/Dates';
import { PaymentStatus, paymentStatuses } from 'constants/paymentStatuses';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Status } from '../Status/Status';
import * as S from './PaymentsTable.styles';
import * as XLSX from 'xlsx';
import { ClaimModel } from '@app/domain/ClaimModel';

interface Status {
  key: number;
  date: Date;
  status: PaymentStatus | undefined;
  invoices: { claimReference: string; url: string }[];
  isAllTime?: boolean;
}

interface PaymentsTableProps {
  payments: Payment[];
}

const today: Date = new Date();

interface Invoice {
  claimReference: string;
  url: string;
  debtorId?: string;
  debtorName?: string;
  debtorCountry?: string;
  creditorId?: string;
  creditorName?: string;
  creditorVatId?: string;
  creditorCountry?: string;
}

type Record = {
  date: Date;
};

export const PaymentsTable: React.FC<PaymentsTableProps> = ({ payments }) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user.user);
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [dataSource, setDataSource] = useState<Status[]>([]);
  const [payouts, setPayouts] = useState<GetPayoutsResponse>();
  const [currentInvoices, setCurrentInvoices] = useState<Invoice[]>([]);

  const anchor = document.createElement('a');
  document.body.appendChild(anchor);

  const handleFileClick = useCallback(
    (url: string, claimReference: string): void => {
      const authHeader = {
        headers: { Authorization: `Bearer ${readToken()}` },
      };

      const cleanClaimReference = claimReference?.replaceAll('/', '-');
      let urlToFetch = url.split(cleanClaimReference + '/').pop();
      urlToFetch = encodeURIComponent(urlToFetch || '');

      if (urlToFetch) {
        fetch(httpApi.defaults.baseURL + '/claims/' + cleanClaimReference + '/file/' + urlToFetch, authHeader).then(
          (response) => {
            if (response.ok) {
              response.blob().then((blobby) => {
                if (urlToFetch !== undefined) {
                  const objectUrl = window.URL.createObjectURL(blobby);
                  anchor.href = objectUrl;
                  anchor.download = urlToFetch;
                  anchor.click();
                  document.body.removeChild(anchor);

                  setTimeout(() => {
                    window.URL.revokeObjectURL(objectUrl);
                  }, 100);
                }
              });
            }
          },
        );
      }
    },
    [anchor],
  );

  const handlePayoutsProcessing = (fetchedPayouts: GetPayoutsResponse) => {
    if (Array.isArray(fetchedPayouts) && fetchedPayouts.length > 0) {
      const newInvoices: Invoice[] = [];
      fetchedPayouts.forEach((payout) => {
        const { debtor, creditor, payoutFileUrl, reference } = payout;

        // used for csv export
        (payout as unknown as Invoice).creditorId = creditor?.id;
        (payout as unknown as Invoice).creditorName = creditor?.businessName;
        (payout as unknown as Invoice).creditorVatId = creditor?.vatId;
        (payout as unknown as Invoice).creditorCountry = creditor?.address?.country;
        (payout as unknown as Invoice).debtorId = debtor?.id;
        (payout as unknown as Invoice).debtorName = debtor?.name;
        (payout as unknown as Invoice).debtorCountry = debtor?.address?.country;

        (payoutFileUrl?.split(', ') || [payoutFileUrl || '']).forEach((fileUrl: string) => {
          if (fileUrl.includes(reference)) {
            newInvoices.push({
              claimReference: reference,
              url: fileUrl,
              debtorId: debtor?.id,
              debtorName: debtor?.name,
              creditorId: creditor?.id,
              creditorName: creditor?.businessName,
              creditorVatId: creditor?.vatId,
              creditorCountry: creditor?.address?.country,
            });
          }
        });
      });

      setCurrentInvoices(newInvoices);
    } else {
      if (activeIndex !== 0) {
        notification.warning({
          message: t('profile.nav.payments.empty'),
          placement: 'topRight',
        });
      }
      setCurrentInvoices([]);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const fetchedInvoices: React.SetStateAction<Invoice[]> = [];
        const paymentToFetch = payments[activeIndex];

        if (paymentToFetch) {
          const lte = new Date(paymentToFetch.date);
          lte.setMonth(lte.getMonth() + 1);
          lte.setDate(lte.getDate() - 1);
          lte.setHours(23, 59, 59, 0);

          const gte = new Date(paymentToFetch.date);
          gte.setHours(0, 0, 0, 0);

          if (lte > today) {
            lte.setDate(today.getDate());
          }

          // Fetch regular period payouts
          const fetchedPayouts: GetPayoutsResponse = await getPayouts({
            paymentDate: { gte, lte },
          });

          setPayouts(fetchedPayouts);
          handlePayoutsProcessing(fetchedPayouts);
        }

        const statusRecords: Status[] = [
          ...payments.map((payment, index) => ({
            key: index,
            date: payment.date,
            status: paymentStatuses.find((status) => status.id === payment.status),
            details: payment,
            invoices: fetchedInvoices,
          })),
        ];

        setDataSource(statusRecords);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payments, t, activeIndex]);

  const parseExcel = useCallback(
    (record: Record) => {
      if (payouts) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const excelData = payouts.map((payout: Partial<ClaimModel> | any) => ({
          [t('claim.id')]: payout.id,
          [t('claim.title')]: payout.title,
          [t('claim.reference')]: payout.reference,
          [t('claim.originalAmountDue')]: payout.originalAmountDue,
          [t('claim.currency')]: payout.currency,
          [t('claim.creditorInterest')]: payout.creditorInterest,
          [t('claim.toRecoverTotal')]: payout.toRecoverTotal,
          [t('claim.feesTotal')]: payout.feesTotal,
          [t('claim.grossFeesTotal')]: payout.grossFeesTotal,
          [t('claim.interestTotal')]: payout.interestTotal,
          [t('claim.grossInterestTotal')]: payout.grossInterestTotal,
          [t('claim.debtCollectorFeesTotal')]: payout.debtCollectorFeesTotal,
          [t('claim.grossDebtCollectorFeesTotal')]: payout.grossDebtCollectorFeesTotal,
          [t('claim.grossCostsTotal')]: payout.grossCostsTotal,
          [t('claim.incomingPaymentsTotal')]: payout.incomingPaymentsTotal,
          [t('claim.paidOutTotal')]: payout.paidOutTotal,
          [t('claim.foreignAmountPaid')]: payout.foreignAmountPaid,
          [t('claim.payoutReference')]: payout.payoutReference,
          [t('claim.payoutPending')]: payout.payoutPlanned,
          [t('claim.payoutDate')]: payout.payoutDate,
          [t('claim.debtorId')]: payout.debtorId,
          [t('claim.debtorName')]: payout.debtorName,
          [t('claim.debtorCountry')]: payout.debtorCountry,
          [t('claim.creditorId')]: payout.creditorId,
          [t('claim.creditorName')]: payout.creditorName,
          [t('claim.creditorCountry')]: payout.creditorCountry,
          [t('claim.creditorVatId')]: payout.creditorVatId,
        }));

        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);

        // Apply bold style to the "foreignAmountPaid" column
        const boldColumns = [t('claim.foreignAmountPaid'), t('claim.paidOutTotal')];
        const boldStyle = { font: { bold: true } };
        const range = XLSX.utils.decode_range(ws['!ref'] || '');

        boldColumns.forEach((column) => {
          const colIndex = Object.keys(excelData[0]).findIndex((key) => key === column);
          for (let row = range.s.r + 1; row <= range.e.r; row++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: colIndex });
            if (ws[cellAddress]) {
              ws[cellAddress].s = boldStyle;
            }
          }
        });

        XLSX.utils.book_append_sheet(wb, ws, 'Payouts');

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.ms-excel' });

        const url: string = window.URL.createObjectURL(blob);
        const link: HTMLAnchorElement = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Dates.format(record.date.getTime(), 'MM-YYYY')}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    [payouts, t],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: ColumnType<any>[] = useMemo(() => {
    return [
      {
        title: t('profile.nav.payments.recipient'),
        dataIndex: 'details.recipient',
        key: 'recipient',
        render: () => (
          <S.RecipientWrapper>
            <S.AvatarWrapper>
              <Avatar src={<UserOutlined />} alt={'User'} shape={'circle'} size={'default'} />
            </S.AvatarWrapper>
            {user?.businessName}
          </S.RecipientWrapper>
        ),
        align: 'left',
      },
      {
        title: t('profile.nav.payments.status.title'),
        dataIndex: 'date',
        key: 'status',
        render: (date: Date) => {
          let result: string = t('profile.nav.payments.status.paid');
          if (date.getMonth() === today.getMonth()) {
            result = t('profile.nav.payments.preliminary');
          }
          return result;
        },
        align: 'center',
      },
      {
        title: t('profile.nav.payments.date'),
        dataIndex: 'date',
        key: 'date',
        render: (date: Date, record: Status) => {
          if (record.isAllTime) {
            return <p>{t('profile.nav.payments.allTime')}</p>;
          }
          return Dates.format(date.getTime(), 'MM/YYYY');
        },
        align: 'center',
      },
      {
        title: t('profile.nav.payments.invoices'),
        render: (record: { key: number }) => {
          if (record.key !== activeIndex) {
            return (
              <a key={'download'} onClick={() => setActiveIndex(record.key)}>
                <Space>
                  {t('profile.nav.payments.downloadInvoices')}
                  <DownloadOutlined />
                </Space>
              </a>
            );
          }
          const invoices = currentInvoices;

          return (
            <S.PaymentHistoryInvoiceCol>
              {!!invoices &&
                invoices.length !== 0 &&
                invoices.map((invoice, index) => {
                  const relevantPayouts = payouts?.filter((payout) => {
                    if (!payout.fileUrls) {
                      return;
                    }
                    return payout.fileUrls?.includes(invoice.claimReference);
                  });
                  // Extract references of relevant payouts
                  let payoutReferences = '';

                  if (relevantPayouts) {
                    payoutReferences = relevantPayouts.map((payout) => payout.reference).join(', ');
                  }
                  return (
                    <Tooltip placement={'top'} title={payoutReferences} key={`${invoice.url}-${index}`}>
                      <a
                        key={invoice.claimReference}
                        onClick={() => handleFileClick(invoice.url, invoice.claimReference)}
                      >
                        <Space>
                          {`${invoice.claimReference}`}
                          <DownloadOutlined />
                        </Space>
                      </a>
                    </Tooltip>
                  );
                })}

              {invoices && invoices.length === 0 && '-'}
            </S.PaymentHistoryInvoiceCol>
          );
        },
        align: 'center',
      },
      {
        title: 'Download',
        align: 'right',
        render: (record) => {
          if (record.key === activeIndex && currentInvoices && currentInvoices.length > 0) {
            return (
              <a
                href="#"
                onClick={() => {
                  parseExcel(record);
                }}
              >
                <Space>
                  {t('profile.nav.payments.download')}
                  <DownloadOutlined />
                </Space>
              </a>
            );
          }
        },
      },
    ];
  }, [t, user?.businessName, activeIndex, currentInvoices, payouts, handleFileClick, parseExcel]);

  const handleSorting = (order: 'newest' | 'oldest') => {
    setIsLoading(true);
    const sortedData = [...dataSource];
    sortedData.sort((a, b) => {
      const dateA = a.date.getTime();
      const dateB = b.date.getTime();
      return order === 'newest' ? dateB - dateA : dateA - dateB;
    });

    setDataSource(sortedData);
    setIsLoading(false);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <S.FormControlWrapper>
            <S.TableSortDropdown
              menu={{
                items: [
                  {
                    key: 'newest',
                    label: (
                      <a
                        onClick={() => {
                          handleSorting('newest');
                        }}
                      >
                        {t('profile.nav.payments.sort.latest')}
                      </a>
                    ),
                  },
                  {
                    key: 'oldest',
                    label: (
                      <a
                        onClick={() => {
                          handleSorting('oldest');
                        }}
                      >
                        {t('profile.nav.payments.sort.oldest')}
                      </a>
                    ),
                  },
                ],
              }}
            >
              <a
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <Space>
                  {t('profile.nav.payments.sort.title')}
                  <DownOutlined />
                </Space>
              </a>
            </S.TableSortDropdown>
            {currentInvoices.length > 0 && (
              <Button
                type={'ghost'}
                onClick={async () => {
                  const uniqueUrls = new Set();
                  for (const item of currentInvoices) {
                    if (!uniqueUrls.has(item.url)) {
                      // do not download files twice
                      uniqueUrls.add(item.url);
                      handleFileClick(item.url, item.claimReference);
                      await sleep(400);
                    }
                  }
                }}
              >
                {t('profile.nav.payments.downloadAll')}
              </Button>
            )}
          </S.FormControlWrapper>
        </Col>
      </Row>
      <S.PaymentHistoryTable
        loading={isLoading}
        size={'middle'}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
    </>
  );
};
